<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        rounded
        filled
        class="rounded-lg"
        background-color="#f9fafc"
        label="Título de la publicación"
        persistent-hint
        v-model="publicationDataComputed.name"
        hint="i"
      >
        <template #message="{}">
          <span style="font-weight: 500"
            >Escribe el título con el que quieres publicar la entrada</span
          >
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        rounded
        filled
        class="rounded-lg"
        background-color="#f9fafc"
        label="Vínculo del video"
        persistent-hint
        hint="i"
        v-model="publicationDataComputed.value"
      >
        <template #message="{}">
          <span style="font-weight: 500"
            >Escriba el link del video cargándolo desde YouTube</span
          >
        </template>
        <template #append>
          <v-icon @click="removeVideo">mdi-close-circle</v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" class="d-flex justify-center">
      <v-card
        elevation="0"
        class="inputCard rounded-md d-flex justify-center align-center"
        width="90%"
        height="502"
      >
        <iframe
          :src="youtubeCut(publicationDataComputed.value)"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-textarea
        rounded
        filled
        hint="i"
        label="Descripción"
        background-color="#f9fafc"
        persistent-hint
        v-model="publicationDataComputed.description"
      >
        <template #message="{}">
          <span style="font-weight: 500"
            >Ingresa una descripción corta de la publicación de hasta 50
            palabras</span
          >
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>
<script>
import { colors } from "@/constants/colors.js";

export default {
  props:{
    publicationData:{
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      url: "",
      color: colors,
      videoFormatSwitch: false,
    };
  },
    computed:{
    publicationDataComputed:{
      get(){
        return this.publicationData
      },
      set(){
        this.$emit('changeData', this.publicationData )
      }
    },
    // getVideoFormat() {
    //   return this.videoFormatSwitch
    //     ? "Cargar video desde liga externa"
    //     : "Cargar video desde directorio Local";
    // },
    // getLoadFrame() {
    //   if (this.videoFormatSwitch) {
    //     return this.externalUrl == "";
    //   }
    //   return this.url == "";
    // },
  },
  methods: {
    removeVideo() {
      this.publicationDataComputed.value = ''
      this.externalUrl = "";
      this.$refs.videoInput.value = null;
      // this.url = "";
    },
    youtubeCut(youtube) {
      let youtubeId = "";

      if (youtube.startsWith("https://youtu.be/")) {
        youtubeId = youtube.split("https://youtu.be/")[1];
      } else {
        if (youtube.includes("?v=")) {
          youtubeId = youtube.split("?v=")[1];
        } else if (youtube.includes("&v=")) {
          youtubeId = youtube.split("&v=")[1];
        }
      }
      youtubeId = youtubeId.split("&")[0];
      return `https://www.youtube.com/embed/${youtubeId}`;
    },
    // getVideo(event) {
    //   let file = null;
    //   if (event.target.files) {
    //     file = event.target.files[0];
    //   } else if (event.dataTransfer.files) {
    //     file = event.dataTransfer.files[0];
    //   }
    //   if (file) {
    //     this.url = URL.createObjectURL(file);
    //   }
    // },
  },
};
</script>
<style scoped>
.innerText {
  letter-spacing: 3px;
  font-weight: 800;
}
.inputCard {
  border: 2px solid #b2e5fd;
  border-style: dashed;
}
</style>