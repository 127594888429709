<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
      rounded
      filled
      class="rounded-lg"
      background-color="#f9fafc"
      label="Título de la publicación"
      persistent-hint
      hint="i"
      v-model="publicationDataComputed.name"
    >
    <template #message="{}">
        <span style="font-weight: 500;">Escribe el título con el que quieres publicar la entrada</span>
    </template>
    </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
      v-model="publicationDataComputed.value"
      rounded
      filled
      class="rounded-lg"
      background-color="#f9fafc"
      label="Cargar liga adjunta"
      hide-details=""
    >
    </v-text-field>
    </v-col>
    <!-- <v-col cols="12" v-if="publicationDataComputed.value!=''">
      <v-card
      elevation="0"
      class="inputCard rounded-md">
        <iframe :src="publicationDataComputed.value" frameborder="0" width="100%" height="700"></iframe>
      </v-card>
    </v-col> -->
    <v-col cols="12">
      <v-textarea
      rounded
      filled
      hint="i"
      label="Descripción"
      background-color="#f9fafc"
      persistent-hint
      v-model="publicationDataComputed.description">
      <template #message="{}">
        <span style="font-weight: 500;">Ingresa una descripción corta de la publicación de hasta 50 palabras</span>
    </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props:{
    publicationData:{
      type: Object,
      default: () => ({})
    },
  },
computed:{
  publicationDataComputed:{
      get(){
        return this.publicationData
      },
      set(){
        this.$emit('changeData', this.publicationData )
      }
    },
}
}
</script>
<style scoped>

.innerText{
letter-spacing: 3px;
font-weight: 800;
}
.inputCard {
border: 2px solid #b2e5fd;
border-style: dashed;
}
</style>