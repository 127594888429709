<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <span style="font-weight: bold; font-size: 18px"
        >Título de la publicación</span
      >
    </v-col>
    <v-col cols="12" class="pb-7">
      <v-text-field
        v-model="publicationDataComputed.name"
        hide-details
        dense
        rounded
        filled
        class="rounded-lg"
        background-color="#f9fafc"
        label="Escribe el título con el que quieres publicar la entrada"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <VueEditor
        v-model="publicationDataComputed.value"
        useCustomImageHandler
        :customModules="customModulesForEditor"
        :editor-toolbar="customToolbar"
        :editorOptions="editorSettings"
        @image-added="handleImageAdded"
      >
      </VueEditor>

    </v-col>
  </v-row>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize";

export default {
  components: {
    VueEditor,
  },
  props: {
    publicationData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      content: "<h1>Some initial content</h1>",
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize },
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "link", "strike"],
        [
          {
            color: [
              "#0A0A0A",
              "#FFFFFF",
              "#FFFF00",
              "#FF0000",
              "#00FF00",
              "#0000FF",
              "#FFC0CB",
              "#800080",
              "#FFA500",
              "#008080",
            ],
          },
          {
            background: [
              "#FFFFFF",
              "#FFFF00",
              "#FF0000",
              "#00FF00",
              "#0000FF",
              "#FFC0CB",
              "#800080",
              "#FFA500",
              "#008080",
            ],
          },
        ],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ direction: "rtl" }, { indent: "-1" }, { indent: "+1" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["image"],
      ]
    };
  },
  methods: {
    async handleImageAdded( file, Editor, cursorLocation, resetUploader ){
      try {
        let formData = new FormData();
        formData.append( 'file', file );       
        formData.append ( 'publicationId', this.publicationDataComputed._id )

        const response = await this.axios.put(
          '/publications/upload-image',
          formData
        )
       // let src = (this.publicationDataComputed.images).slice(-1)[0]
       this.publicationDataComputed.images = response.data.publication.images
        
         let src = (this.publicationDataComputed.images).slice(-1)[0]
        console.log(src);
        Editor.insertEmbed(cursorLocation, "image", src )
      } catch (error) {
        console.log(error);
      }

    },
  },
  computed: {
    publicationDataComputed: {
      get() {
        return this.publicationData;
      },
      set() {
        this.$emit("changeData", this.publicationData);
      },
    },
  },
};
</script>