<template>
  <div class="over-all">

    <div @click="toggleOptionsMenu">
      <div v-if="showOptionsMenu" class="options-menu">
        <div class="close-menu" @click.stop="closeMenu">
        <i class="fas fa-times"></i>
        </div>


        <button class="menu-option" @click.stop="navigateToFAQ">
          <i class="icon fa fa-question-circle"></i> Preguntas Frecuentes
        </button>
        <button class="menu-option" @click.stop="openChatFromMenu">
          <i class="icon fa fa-comments"></i> Contacto
        </button>
      </div>

      <beautiful-chat

      
        
        :participants="participants"
        :titleImageUrl="titleImageUrl"
        :onMessageWasSent="onMessageWasSent"
        :messageList="messageList"
        :newMessagesCount="newMessagesCount"
        :isOpen="isChatOpen"
        :close="closeChat"
        :icons="icons"
        :showEmoji="true"
        :showFile="false"
        :showEdition="false"
        :showDeletion="false"
        :showTypingIndicator="showTypingIndicator"
        :showLauncher="true"
        :showCloseButton="true"
        :colors="colors"
        :alwaysScrollToBottom="alwaysScrollToBottom"
        :messageStyling="messageStyling"
        @onType="handleOnType"
        @edit="editMessage"
      >
        <template v-slot:header>
          <v-row class="ma-0 ml-5" align="center">
            <span class="font-weight-bold"> WeSupport </span>
          </v-row>
        </template>
      </beautiful-chat>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "app",
  data() {
    return {
      showOptionsMenu: false,

      thread: null,
      firstMessage: true,
      icons: {
        open: {
          img: "https://img.icons8.com/ios/50/FFFFFF/help--v1.png",
          name: "default",
        },
        close: {
          img: "https://img.icons8.com/windows/96/FFFFFF/macos-close.png",
          name: "default",
        },
        file: {
          img: "https://avatars3.githubusercontent.com/u/37018832?s=200&v=4",
          name: "default",
        },
        closeSvg: {
          img: "https://avatars3.githubusercontent.com/u/37018832?s=200&v=4",
          name: "default",
        },
      },
      participants: [
        {
          id: "assistant",
          name: "Support",
          imageUrl: "https://cdn-icons-png.flaticon.com/512/4712/4712009.png",
        },
        {
          id: "me",
          name: "Me",
          imageUrl: "https://cdn-icons-png.flaticon.com/512/4712/4712009.png",
        },
      ], 
      titleImageUrl:
        "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
      messageList: [
        {
          type: "text",
          author: `assistant`,
          data: { text: `¡Hola! ¿En qué puedo ayudarte hoy?` },
        },
      ], 
      newMessagesCount: 0,
      isChatOpen: false, 
      showTypingIndicator: "", 
      colors: {
        header: {
          bg: "#1b273e",
          text: "#ffffff",
        },
        launcher: {
          bg: "#1b273e",
        },
        messageList: {
          bg: "#ffffff",
        },
        sentMessage: {
          bg: "#1b273e",
          text: "#ffffff",
        },
        receivedMessage: {
          bg: "#eaeaea",
          text: "#222222",
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867",
        },
      }, 
      alwaysScrollToBottom: false, 
      messageStyling: true, 
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    additionalContext() {
      let context = "system: información sobre el usuario: \n";
      if (this.user) {
        context += "el usuario ya inició sesión \n";

        if (this.user.role == "MEMBER") {
          context += "el usuario es un miembro \n";

          if (this.user.validationStatus == "ACTIVE") {
            context += "el usuario ya hizo los pagos de su membresía \n";
          } else {
            context += "el usuario no ha hecho los pagos de su membresía \n";
          }

          if (!this.user.branch) {
            context +=
              "el usuario no tiene una sucursal (podría generar algun error en caso de que su organizacion sí tenga sucursales) \n";
          }
        } else {
          context += "el usuario es un administrador \n";
          if (this.user.accountSetup == "VALIDATED") {
            context += "el usuario ya integró su cuenta de banco \n";
          }
          if (this.user.accountSetup == "NOT_STARTED") {
            context += "el usuario no ha integrado su cuenta de banco \n";
          }

          if (this.user.bankInformation) {
            context += "el usuario ya integró su cuenta de banco \n";
          } else {
            context += "el usuario no ha integrado su cuenta de banco \n";
          }

          if (this.user.invoiceSetup == true) {
            context += "el usuario ya integró sus datos de facturación \n";
          } else {
            context += "el usuario no ha integrado sus datos de facturación \n";
          }
        }
      }
      return context;
    },
  },
  methods: {
    openChatFunction() {
    this.isChatOpen = true;
  },
    toggleOptionsMenu() {
      if (!this.isChatOpen) {
        this.showOptionsMenu = !this.showOptionsMenu;
      }
    },

  navigateToFAQ() {
    this.$router.push({ name: 'mesa-ayuda' });
    this.showOptionsMenu = false;
  },

  openChatFromMenu() {
      this.openChat();
      this.closeMenu(); 
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;

        this.onMessageWasSent({
          author: "assistant",
          type: "text",
          data: { text },
        });
      }
    },
    onMessageWasSent(message) {

      this.messageList = [...this.messageList, message];
      if (message.author === "me") {
        this.showTypingIndicator = "assistant";
        this.sendMessageToThread(message.data.text);
      }
    },
    openChat() {
      this.isChatOpen = true;
      this.newMessagesCount = 0;
      if (this.thread == null) {
    
        this.getThread();
      }
    },
    closeChat() {
      this.isChatOpen = false;
      this.showOptionsMenu = false
    },
    closeMenu(event) {
    this.showOptionsMenu = false;
    event.stopPropagation(); 
  },
    handleScrollToTop() {
      
    },
    handleOnType() {
      console.log("Emit typing event");
    },
    editMessage(message) {},
    async getThread() {
      const thread = await this.axios({
        method: "GET",
        url: "support/create-thread",
      });
      

      this.thread = thread.data.threadId;
      
    },
    async sendMessageToThread(text) {
      let toSend = text;

      if(this.firstMessage){
        toSend = this.additionalContext + text;
        this.firstMessage = false;
      }

      console.log(this.additionalContext);
      const message = await this.axios({
        method: "POST",
        url: "support/send-message",
        data: {
          threadId: this.thread,
          message: toSend,
        },
      });

      this.showTypingIndicator = "";
      this.sendMessage(message.data.response);
    },
  },
};
</script>

<style scoped>
.over-all {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  margin: 10px;
}
.options-menu {
  position: absolute;
  right: 10px;
  bottom: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 300px;
  z-index: 10000;
}

.close-menu {
  text-align: right;
  cursor: pointer;
}

.menu-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.menu-option i {
  margin-right: 10px;
}

.menu-option:hover {
  background-color: #f6f8fa;
}



</style>